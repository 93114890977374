import { NavLink, useLocation } from "react-router-dom";

function Menu() {
    const { pathname } = useLocation();

    return (
        <div className="Menu">
            <ul>
                {pathname !== "/" && (
                    <li>
                        <NavLink to="/">home</NavLink>
                    </li>
                )}
                <li>
                    <NavLink to="/about">about</NavLink>
                </li>
            </ul>
        </div>
    );
}
export default Menu;
