import { collections } from "collections";
import { Picture } from "components";
import { NavLink } from "react-router-dom";
// import OverviewGrid from "components/OverviewGrid/OverviewGrid";

function Home() {
    return (
        <div className="Home">
            <h1>Jolie Laide Photography</h1>
            {/* <div>
                <OverviewGrid />
            </div> */}
            <div className="overview">
                {collections.map((collection) => {
                    return (
                        collection &&
                        collection.visible && (
                            <NavLink to={`/series/${collection.route}`}>
                                <Picture mode="cover" img={collection.cover} />
                            </NavLink>
                        )
                    );
                })}
            </div>
        </div>
    );
}

export default Home;
