function Picture({
    mode,
    img,
}: {
    mode: string;
    img: { mobile: string; desktop: string };
}) {
    return (
        <div className="Picture">
            {mode === "cover" && (
                <>
                    <div
                        className={"cover mobile"}
                        style={{
                            backgroundImage: `url("${img.mobile}")`,
                        }}
                    ></div>
                    <div
                        className={"cover desktop"}
                        style={{
                            backgroundImage: `url("${img.desktop}")`,
                        }}
                    ></div>
                </>
            )}
        </div>
    );
}
export default Picture;
