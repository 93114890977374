import { me } from "collections";

function About() {
    return (
        <div className="About">
            <h1>About</h1>
            <p className="quote">
                "To create something of beauty, is reason enough."
            </p>
            <p>
                Find me on&nbsp;
                <a
                    href="https://www.instagram.com/jolie.laide.photography/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    instagram
                </a>
                .
            </p>
            <div
                className={"picture"}
                style={{
                    backgroundImage: `url(${me})`,
                }}
            ></div>
        </div>
    );
}
export default About;
