import { useEffect, useState } from "react";
import { CollectionType, collections } from "../../collections";
import { PhotoGrid, Picture } from "../../components";

function Collection({ id }: { id: string }) {
    const [collection, setCollection] = useState<CollectionType | null>(null);

    useEffect(() => {
        const collection = collections.find((c) => c.id === id);
        if (collection && collection.title?.length) {
            setCollection(collection);
        }
    }, [id]);

    return (
        collection && (
            <div className="Collection">
                <h1>{collection?.title}</h1>
                <div className="intro">
                    <Picture mode="cover" img={collection.cover} />
                    <div className="description">
                        {collection?.description?.length &&
                            collection.description.map((descr) => (
                                <p>{descr}</p>
                            ))}
                    </div>
                </div>

                {collection && collection.pictures?.length && (
                    <PhotoGrid collection={collection} />
                )}
            </div>
        )
    );
}

export default Collection;
