import { Routes, Route } from "react-router-dom";

import { About, Home, Collection, NotFound } from "./components";

const Router = () => (
    <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        {/* <Route path="/jolielaide/01" element={<Collection id="01" />}></Route>
        <Route path="/jolielaide/02" element={<Collection id="02" />}></Route> */}
        <Route path="/series/vapur" element={<Collection id="01" />}></Route>
        <Route path="/series/fog" element={<Collection id="03" />}></Route>

        <Route path="/*" element={<NotFound />}></Route>
    </Routes>
);
export default Router;
