export interface CollectionType {
    id: string;
    title: string;
    route?: string;
    description?: string[];
    cover: {
        mobile: string;
        desktop: string;
    };
    visible: boolean;
    metadata: {
        location: string;
        year: string;
        medium: string;
        film?: string;
        camera?: string;
    };
    colors: string[];
    pictures?: { path: string; dir: string; alt: string }[];
}

// standard 2400x1600
// desktop cover LANDSCAPE 500x350
// mobile cover SQUARE 400x400

export const me =
    "https://res.cloudinary.com/jolie-laide/image/upload/v1662673613/jolielaide/e96fe298-8293-4862-8ff7-4a117464b843.png";

export const collections: CollectionType[] = [
    {
        id: "01",
        route: "vapur",
        title: "Vapur",
        visible: true,
        description: [
            "Vapur (Turkish for 'ferry'), is an ongoing series.",
            "In many ways, Istanbul's ferries embody the city itself: connecting 2 continents, bringing together people of different cultures, opinions and beliefs.",
            "Commuters, tourists, friends, locals in suits on their way for a family visit: they sit peacefully side by side.",
            "All equal, at least for a minute or 10.",
        ],
        cover: {
            mobile: "https://res.cloudinary.com/jolie-laide/image/upload/v1662671732/jolielaide/3b0bf266-6d3d-4fa7-8973-2be37d0d7400.png",
            desktop:
                "https://res.cloudinary.com/jolie-laide/image/upload/v1662715434/jolielaide/0226eb9a-1c59-4700-a238-610d3bc3f24e.png",
        },
        metadata: {
            location: "Istanbul",
            year: "2021",
            medium: "digital",
        },
        colors: ["#466983", "#b5c6e0", "#bf7052", "#e1e7f3"],
        pictures: [
            {
                path: "v1662679391/jolielaide/bcdc2462-4857-4d4b-bdc3-a9eae6c90c1e.png",
                dir: "L",
                alt: "picture through a ferry window showing people inside and outside, with the skyline in the background",
            },
            {
                path: "v1662679389/jolielaide/a7ced011-d758-4622-9ed2-c41f7a6df0df.png",
                dir: "P",
                alt: "woman looking at the skyline from the ferry",
            },
            {
                path: "v1662679279/jolielaide/f5806366-4fcd-4356-a541-21cc4462edac.png",
                dir: "L",
                alt: "silhouette of a seagull as seen through the plastic curtains of the ferry",
            },
            {
                path: "v1662679398/jolielaide/78f446af-2ee1-48f5-b6f5-a0f11b9b6fb1.png",
                dir: "L",
                alt: "woman's legs in a winter skirt on a ferry",
            },
            {
                path: "v1662679391/jolielaide/6954b423-2267-47d7-877d-73a706ea417d.png",
                dir: "P",
                alt: "girl feeding a seagull from the ferry while her parents cheer her on",
            },
            {
                path: "v1662679391/jolielaide/a7a39f39-b79b-4a44-a04e-81c258aee131.png",
                dir: "P",
                alt: "2 girls on a ferry wearing identical outfits and identical shoes",
            },
        ],
    },
    {
        id: "02",
        title: "Zakynthos Fuji",
        visible: false,
        cover: {
            mobile: "",
            desktop: "",
        },
        metadata: {
            location: "Zakynthos, Greece",
            year: "2020",
            medium: "analogue",
            film: "expired Fuji Superia Xtra 400",
        },
        colors: ["#6c6984", "#a1b0bb", "#344670", "#885144"],
    },
    {
        id: "03",
        title: "Fog",
        route: "fog",
        visible: true,
        description: [
            "A foggy morning in Istanbul on expired Kodak film.",
            "A moody analogue series.",
        ],
        cover: {
            mobile: "https://res.cloudinary.com/jolie-laide/image/upload/v1662713173/jolielaide/11473b14-1764-4ee9-8d62-2b3b26798826.png",
            desktop:
                "https://res.cloudinary.com/jolie-laide/image/upload/v1662713173/jolielaide/0bb98e65-f28a-4991-836b-ab69e744708a.png",
        },
        metadata: {
            location: "Istanbul",
            year: "2020",
            medium: "analogue",
        },
        colors: ["#466983", "#b5c6e0", "#bf7052", "#e1e7f3"],
        pictures: [
            {
                path: "v1662711611/jolielaide/78d5702e-f124-4c79-af43-52f8b79bc1cb.png",
                dir: "P",
                alt: "A man walking on a square on a foggy morning in Istanbul",
            },
            {
                path: "v1662712098/jolielaide/9088881e-63bb-4f3e-b66c-601aa98a3692.png",
                dir: "L",
                alt: "dog sleeping on the street on a foggy Istanbul morning",
            },
            {
                path: "v1662713354/jolielaide/21fc4ea0-12fa-44c7-bf8b-f17d2676cc55.png",
                dir: "P",
                alt: "Man walking the street on a foggy Istanbul morning with a",
            },
            {
                path: "v1662711615/jolielaide/4e671cbc-3123-47ec-90d4-da7c58d95ea6.png",
                dir: "L",
                alt: "The silhouette of a crow sitting on a lantern on a foggy morning in Istanbul",
            },
            {
                path: "v1662711613/jolielaide/58689dfd-99b9-4e75-bba6-4a06318c93c7.png",
                dir: "P",
                alt: "A street vendor of simit getting ready for the day on a foggy morning in Istanbul",
            },
            {
                path: "v1662711611/jolielaide/3ae8ff28-8d09-4b6c-bd7c-e53a2f626858.png",
                dir: "L",
                alt: "A simit seller surrounded by pigeons on a foggy morning in Istanbul",
            },
            {
                path: "v1662711610/jolielaide/28656b35-db15-4d67-bf1f-dd1f772ba027.png",
                dir: "L",
                alt: "Man walking Galata bridge on a foggy Istanbul morning",
            },
            {
                path: "v1662711608/jolielaide/3557757e-c39d-496b-91a3-62b6cbf3c41f.png",
                dir: "P",
                alt: "Taxi drivers having breakfast from the trunk of one of their cars on a foggy Istanbul morning",
            },
            {
                path: "v1662711606/jolielaide/43887ccd-7295-4d56-b593-8584dab4ed69.png",
                dir: "L",
                alt: "Street sweeper working on a foggy Istanbul morning",
            },
            {
                path: "v1662711605/jolielaide/87687480-6a53-4536-a909-08e34d6679d0.png",
                dir: "L",
                alt: "Man walking the street on a foggy Istanbul morning",
            },
        ],
    },
];
