import { CollectionType } from "collections";
function PhotoGrid({ collection }: { collection: CollectionType }) {
    const baseUrl = "https://res.cloudinary.com/jolie-laide/image/upload";
    return (
        <>
            <div className="PhotoGrid">
                {collection?.pictures?.map((picture, i) => (
                    <div
                        className={`picture ${
                            picture.dir === "L" ? "landscape" : "portrait"
                        }`}
                        key={i}
                        style={{
                            backgroundImage: `url("${baseUrl}/${picture.path}")`,
                        }}
                    ></div>
                ))}
            </div>
        </>
    );
}

export default PhotoGrid;
