import Menu from "components/Menu/Menu";
import Router from "./Router";
function App() {
    return (
        <div className="App">
            <Menu />
            <Router />
        </div>
    );
}

export default App;
